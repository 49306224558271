<template>
    <div class="OperationRide" :class="{ 'is-loading': isFetchingData }">
        <Headline title="Schichten mit 6h oder mehr: " :highlight="shiftsWith6hOrMore">
            <p>
                Unangemeldet Schichten aufsummiert:
                {{ shiftsWithoutRegistration }}
            </p>
        </Headline>
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    :items="cars"
                    :selected="selectedCar"
                    placeholder="Kennzeichen"
                    @onItemSelect="handleCarSelect"
                />
                <Datepicker
                    @onChange="handleDateChange"
                    :startDate="selectedFrom"
                    :endDate="selectedTo"
                />
                <Button @onClick="handleFetchOperatingRecords" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div>
                <!-- <Download title="Download PDF" type="pdf" @onDownload="handleDownload" /> -->
                <Download title="Download CSV" type="csv" @onDownload="handleDownload" />
            </div>
        </Toolbar>
        <operation-ride-table
            :operationRecords="operationRecords"
            @onSumOfAllGaps="handleSumOfAllGaps"
        />
    </div>
</template>

<script>
import { endOfDay, format, formatISO, startOfDay, startOfMonth, subDays } from 'date-fns';
import axios from 'axios';
import lazyloadComponent from '@/lib/lazyloadComponent';
import Button from '@/components/widgets/Button';
import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import OperationRideTable from './components/OperationRideTable';
import { distanceToString, priceToEuroString, round2d } from '@/lib/helper';

export default {
    name: 'OperationRide',
    components: {
        Button,
        Datepicker,
        Download,
        Dropdown,
        Headline,
        Toolbar,
        OperationRideTable,
    },
    data: () => ({
        isFetchingData: false,
        operationRecords: [],
        shiftsWithoutRegistration: 0,
        drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
        cars: JSON.parse(localStorage.getItem('cars')),
        selectedCar: null,
        selectedFrom: startOfMonth(new Date()),
        selectedTo: new Date(),
    }),
    computed: {
        shiftsWith6hOrMore() {
            return this.operationRecords.filter(
                r =>
                    new Date(r.endAt).getTime() - new Date(r.startAt).getTime() >=
                    6 * 60 * 60 * 1000,
            ).length;
        },
    },
    methods: {
        handleSumOfAllGaps(sum) {
            this.shiftsWithoutRegistration = distanceToString(sum);
        },
        handleDateChange(daterange) {
            this.selectedFrom = daterange.from;
            this.selectedTo = daterange.to;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        handleDownload(type) {
            if (type === 'pdf') {
            } else if (type === 'csv') {
                const csv = this.operationRecords.map(r => {
                    return {
                        Fahrer: r.driver?.name,
                        Kennzeichen: r.licenseNumber,

                        Beginn: format(new Date(r.startAt), 'dd.MM.yyyy HH:mm'),
                        Ende: format(new Date(r.endAt), 'dd.MM.yyyy HH:mm'),
                        Dauer: `${Math.floor(
                            (new Date(r.endAt).getTime() - new Date(r.startAt).getTime()) /
                                (1000 * 60 * 60),
                        )}h ${Math.floor(
                            ((new Date(r.endAt).getTime() - new Date(r.startAt).getTime()) /
                                (1000 * 60 * 60)) %
                                60,
                        )}m`,
                        'KM Abfahrt': distanceToString(r.mileageStart),
                        'KM Ankunft': distanceToString(r.mileageEnd),
                        'Gesamt KM': distanceToString(r.totalDistance),
                        'Besetzt KM': distanceToString(r.hiredDistance),
                        Touren: r.tripsCount,
                        'Eur/KM': priceToEuroString(
                            r.finalTotalAmount / Math.max(r.totalDistance / 1000, 1),
                        ),
                        'Umsatz (€)': round2d(r.finalTotalAmount / 100).format(),
                    };
                });
                const csvString = `${Object.keys(csv[0]).join(';')}\n${csv
                    .map(r => Object.values(r).join(';'))
                    .join('\n')}`;
                const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);

                const filename = `Schichtbericht_${this.selectedCar?.name || ''}_${format(
                    this.selectedFrom,
                    'dd.MM.yyyy',
                )}_${format(this.selectedTo, 'dd.MM.yyyy')}.csv`;
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        async handleFetchOperatingRecords() {
            this.isFetchingData = true;
            const queryData = {
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.operationRecords = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.handleFetchOperatingRecords();
        }, 300);
    },
};
</script>

<style lang="scss">
.OperationRide {
    @extend %contentWrapper;
    @extend %page;
}
</style>
